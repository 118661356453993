import styled from 'styled-components';

export const BannerHolder = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 100px;
  padding: 0 20%;

  h1 {
    font-size: 36px;
    font-weight: 600;
    color: var(--main-bg);

    text-align: center;
  }
  h2 {
    font-size: 20px;
    font-weight: 400;
    color: #000;
    margin: 10px 0 0;

    text-align: center;

    padding-bottom: 15px;
  }
  hr {
    width: 10px;
    height: 2px;

    border: none;
    background-color: var(--main-bg);
  }

  @media (max-width: 768px) {
    & {
      padding: 0 5%;
      margin-top: 50px;
    }
  }
`;

export const ContatosHolder = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 30px 0;

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    color: #000;

    margin: 0 30px;
    font-size: 20px;

    svg {
      margin-right: 10px;
    }
  }

  @media (max-width: 768px) {
    & {
      flex-direction: column;
    }
    a {
      margin: 10px 0;
    }
  }
`;

export const ContentHolder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px 15% 0;

  @media (max-width: 1366px) {
    padding-left: 5%;
    padding-right: 5%;
  }

  iframe {
    width: 50%;
    min-height: 500px;
    margin-right: 25px;
    border-radius: 30px;

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 768px) {
    & {
      flex-direction: column;
    }
    iframe {
      width: 90%;
      min-height: 250px;
      margin: 0px;
    }
  }
`;

export const FormHolder = styled.div`
  width: 50%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 25px;

  form {
    width: 100%;
    label {
      font-size: 18px;
    }
    .inputHolder,
    .textareaHolder {
      margin: 5px 0 15px;
      border-radius: 10px;
    }
    .textareaHolder {
      min-height: 100px;
      padding: 10px 15px;
      display: flex;
      textarea {
        flex: 1 1;
        height: 100% !important;
        width: 100% !important;
        resize: none;
      }
    }
    button[type='submit'] {
      margin-top: 10px;

      height: 100%;
      border: none;
      background-color: var(--main-color);
      color: #000;
      font-weight: 600;
      font-size: 18px;
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);

      padding: 10px 50px;
      border-radius: 30px;

      text-align: center;
    }
  }
  @media (max-width: 768px) {
    & {
      width: 90%;
      margin: 30px 0 0;
    }
  }
`;
