import React, { useCallback, useRef, useState } from 'react';
import { PageProps } from 'gatsby';
import Layout from 'components/_layout';
import { FaAt, FaPhoneAlt, FaWhatsapp } from 'react-icons/fa';
import { FormHandles } from '@unform/core';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import api from 'services/api';
import getValidationErrors from 'utils/getValidationErrors';
import Input from 'components/Input';
import Textarea from 'components/Input/textarea';
import {
  BannerHolder,
  ContatosHolder,
  ContentHolder,
  FormHolder,
} from '../styles/pages/contato';

interface FormData {
  name: string;
  email?: string;
  phone?: string;
  message: string;
}

const Contact = (props: PageProps) => {
  const { location } = props;

  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(async (data: FormData, { reset }) => {
    try {
      setLoading(true);

      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string().email('Insira um e-mail válido'),
        phone: Yup.string(),
        message: Yup.string().required('Mensagem obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (
        (!data.email || data.email === '') &&
        (!data.phone || data.phone === '')
      ) {
        toast.error(
          '❌ Você precisa informar ao menos um e-mail ou telefone para entrarmos em contato.',
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }

      api
        .post('/contatos', data)
        .then(res => {
          toast.success('✔️ Mensagem enviada com sucesso.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          reset();
        })
        .catch(err => {
          toast.error('❌ Erro ao enviar a mensagem.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.error(`Erro: ${err}`);
        });
    } catch (err) {
      console.error(`Erro: ${err}`);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        let error = '❌ Erro ao enviar a mensagem.';

        if (errors.name) {
          error = `❌ ${errors.name}`;
        } else if (errors.email) {
          error = `❌ ${errors.email}`;
        } else if (errors.message) {
          error = `❌ ${errors.message}`;
        }

        toast.error(error, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        return;
      }

      toast.error('❌ Erro ao enviar a mensagem.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Layout pathname={location.pathname}>
      <BannerHolder>
        <h1>Entre em contato!</h1>
        <h2>
          Se tiver alguma dúvida ou comentário, por favor entre em contato
          preenchendo o formulário abaixo ou através de nosso whatsapp, telefone
          ou e-mail
        </h2>
        <hr />
      </BannerHolder>
      <ContatosHolder>
        <a
          href="https://api.whatsapp.com/send?phone=5541991633300&text=Ol%C3%A1%2C%20FG%20Livraria!%20"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp className="whatsapp" size={25} />
          (41) 99163-3300
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=5541991633300&text=Ol%C3%A1%2C%20FG%20Livraria!%20"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaPhoneAlt className="phone" size={25} />
          (41) 3016-3427
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=5541991633300&text=Ol%C3%A1%2C%20FG%20Livraria!%20"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaAt className="email" size={25} />
          contato@fglivraria.com
        </a>
      </ContatosHolder>
      <ContentHolder>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.9015118927346!2d-49.24191298435233!3d-25.408107138252063!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce5d23584964d%3A0xf9fe134c25edeee5!2sSolu%C3%A7%C3%A3o%20Distribuidora%20de%20Livros%20e%20Livraria!5e0!3m2!1spt-BR!2sbr!4v1606697998171!5m2!1spt-BR!2sbr"
          frameBorder="0"
          allowFullScreen={false}
          aria-hidden="false"
          title="maps"
        />
        <FormHolder>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <label htmlFor="name">Nome*:</label>
            <Input name="name" placeholder="Digite seu nome" />
            <label htmlFor="email">E-mail:</label>
            <Input name="email" type="email" placeholder="Digite seu e-mail" />
            <label htmlFor="phone">Telefone:</label>
            <Input
              name="phone"
              type="phone"
              placeholder="Digite seu telefone"
            />
            <label htmlFor="message">Mensagem*:</label>
            <Textarea name="message" placeholder="Digite sua mensagem" />
            <button type="submit">
              {loading ? 'Carregando...' : 'Enviar'}
            </button>
          </Form>
        </FormHolder>
      </ContentHolder>
    </Layout>
  );
};

export default Contact;
